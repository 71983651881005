export const useBlogUrl = (pathWithLocale: string = "/"): string => {
    // Don't link to /en, it exists but is not the canonical English blog home page
    return `${useRuntimeConfig().public.blogBaseUrl}${pathWithLocale === "/en" ? "/" : pathWithLocale}`;
};

export const useDocsUrl = (path: string = "/"): string => {
    // docs only supports english for now
    return `/docs${path}`;
};

export function useApiLocaleUrl() {
    const { locale } = useI18n();
    return (endpoint: string, query?: URLSearchParams): string => {
        query ??= new URLSearchParams();
        query.append("lang", locale.value);
        return `/${endpoint}?${query.toString()}`;
    };
}

export function useLangPath() {
    const { locale } = useI18n();
    return (path: string): string => {
        return `/${locale.value}${path}`;
    };
}
